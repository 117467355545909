.background-container {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.spinner-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    background: white;
    padding: 8px;
    border-radius: 20px;
    width: 30vw;
}

.spinner {
    width: 50%;
}

.content-description {
    margin-top: 100px;
    margin-bottom: 340px;
    width: 80%;
}

.qr-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 252px;
    width: 204px;
    margin-bottom: 15%;
    border-radius: 16px;
    padding: 28px 12px;
    text-align: center;
    background-color: var(--color-background-primary);
}

.qr-image {
    height: 180px;
    width: 180px;
    margin-bottom: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background-primary);
    border-radius: 20px;
    padding: 28px 32px;
    max-height: 50%;
}

.backdrop-container {
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.32);
}

.icon-container {
    position: absolute;
    border-radius: 8px;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    max-width: max-content;
    text-align: center;
    margin: auto;
    margin-bottom: 3rem;
    background-color: var(--color-background-primary);
}

.description-container {
    margin-left: -10vw;
    padding: 12px 16px 12px 21px;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.6);
}

.partner-container {
    display: flex;
    align-items: center;
    margin: auto;
}

.icon-position {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.city-image {
    display: flex;
    height: 120px;
    width: 100%;
    background-image: url('/map-layout.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    object-fit: cover;
}

.typography-margin {
    margin-right: 3px;
}

.map-button {
    background-color: rgba(244, 244, 244, 1);
    color: rgba(79, 79, 79, 1);
    width: 120px;
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: -2px -2px 8px 0px rgba(0, 0, 0, 0.04);
}

.icon-brand {
    width: 40px;
    height: 40px;
    border-radius: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
}

.icon-brand-modal-header {
    position: absolute;
    top: -10%;
    left: 38%;
    transform: translate(0, -10%);
    width: 48px;
    height: 48px;
    border-radius: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-line-ellipsis {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.button-link {
    width: 100%;
}

.accordion-item {
    background-color: rgba(247, 247, 247, 1);
    border-radius: 12px;
}

.accordion-button {
    padding: 20px !important;
}

.button-secondary-new-color {
    background-color: rgba(205, 219, 211, 1);
    color: rgba(3, 77, 33, 1);
}

.button-secondary-new-color:hover {
    background-color: rgba(205, 219, 211, 1);
    color: rgba(3, 77, 33, 1);
}

.button-accent-new-color {
    background-color: rgba(36, 113, 67, 1);
    color: rgba(255, 255, 255, 1);
}

@media (min-width: 850px) {
    .spinner {
        width: 20%;
    }

    .content-description {
        background-color: rgba(255, 255, 255, 0.32);
        justify-content: space-between;
        backdrop-filter: blur(20px);
        border-radius: 20px;
        width: 640px;
        max-height: 95%;
    }

    .button-link {
        width: 240px;
    }

    .description-container {
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 16px;
        border-bottom-left-radius: 0;
        width: 204px;
        margin: auto;
        backdrop-filter: blur(25px);
        padding: 12px 16px 12px 20px;
    }

    .city-image {
        display: flex;
        height: 140px;
        width: 100%;
        background-image: url('/map-layout.png');
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 12px;
        object-fit: cover;
    }

    .tebe-logo {
        text-align: end;
    }

    .map-button {
        margin: auto;
        background-color: rgba(244, 244, 244, 1);
        color: rgba(79, 79, 79, 1);
        width: 200px;
    }

    .qr-container {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 252px;
        width: 204px;
        margin-bottom: 16px;
        border-radius: 16px;
        padding: 28px 12px;
        text-align: center;
        background-color: var(--color-background-primary);
    }
}

@media (min-width: 1023px) {
    .spinner {
        width: 20%;
    }
    .icon-container {
        position: absolute;
        border-radius: 8px;
        top: -2%;
        left: 28%;
        transform: translate(28%, -2%);
        padding: 8px 12px;
        max-width: max-content;
        text-align: center;
        margin: auto;
        margin-bottom: 3rem;
        background-color: var(--color-background-primary);
    }

    .content-description {
        background-color: rgba(255, 255, 255, 0.32);
        justify-content: space-between;
        backdrop-filter: blur(20px);
        border-radius: 20px;
        width: 640px;
        max-height: 95%;
    }

    .description-container {
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 16px;
        border-bottom-left-radius: 0;
        width: 204px;
        margin: auto;
        backdrop-filter: blur(25px);
        padding: 12px 16px 12px 20px;
    }

    .tebe-logo {
        text-align: end;
    }
}
